import React from 'react';

const BackgroundImage = ({ imageInfo, classes, children, height }) => {
  const { childImageSharp } = imageInfo;
  const getImageSource = () => {
    if (!!imageInfo && !!imageInfo.childImageSharp) {
      return imageInfo.childImageSharp.fluid.src;
    }
    if (!!childImageSharp) {
      return childImageSharp.fluid.src;
    }
    if (!!imageInfo && typeof imageInfo === 'string') {
      return imageInfo;
    }
    return null;
  };
  return (
    <div
      className={classes}
      style={{
        backgroundSize: 'cover',
        backgroundImage: `url(${getImageSource()})`,
        position: 'relative',
      }}>
      {children}
    </div>
  );
};

export default BackgroundImage;
